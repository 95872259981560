import styles from './MoreInfoCourse.module.css';
import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, CircularProgress, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Typography, useMediaQuery, useTheme } from "@mui/material"
import { CardMinimalCourseInfo, MenuDrawer, PnoWrap } from "../../components"
import { courseService, GET_DISCIPLINE, Head } from "../../shared"
import { useParams } from "react-router-dom"
import { useAuthContext } from "../../shared/hooks"
import { toast } from "react-toastify"

export function MoreInfoCourse() {
  const { palette, breakpoints } = useTheme()
  const sm = useMediaQuery(breakpoints.up('sm'))
  const { id: courseId } = useParams();

  const [course, setCourse] = useState(null)
  const [disciplineSelected, setDisciplineSelected] = useState(null)

  const [discipline, setDiscipline] = useState([])

  const [disciplineAccount, setDisciplineAccount] = useState(0);
  const [totalAccount, setTotalAccount] = useState(0);
  const [page, setPage] = useState(1);
  const { token } = useAuthContext()

  const [loading, setLoading] = React.useState(false);
  const [Change, setChange] = React.useState(false);

  const [checked, setChecked] = React.useState([]);
  const [checkedToRemove, setCheckedToRemove] = React.useState([]);
  const [Aux, setAux] = React.useState([]);

  const handleToggle = (value) => () => {
    console.log('entrei', value, {checked});
    
    let newChecked = [...checked];
    if (newChecked.indexOf(value) === -1) {
      setChecked(newChecked);
      newChecked.push(value);
    } else {
      console.log('entrei\n\ndelete');
      
      newChecked.splice(newChecked.indexOf(value), 1);
      console.log({newChecked});
      setChecked(newChecked);
      
      setCheckedToRemove(old => [...old, value])
    }
  };

  useEffect(() => {
    if (courseId && typeof Number(courseId) === 'number') {
      courseService.getOne(courseId).then((data) => {
        setCourse(data.data)
      }).catch(err => {
        console.log({ err });
      })
    }
  }, [])

  useEffect(() => {
    if (course && course !== null) {
      const getSubjectCourse = async () => {
        try {
          const { url, options } = courseService.getAllCourseJoinDiscipline(course?.name, token)
          const response = await fetch(url, options);
          const result = await response.json();
          setDisciplineSelected(result.data[0])
        } catch (error) {
          toast.error(error.message)
        }
      }
      getSubjectCourse();
    }
  }, [course, Change])

  useEffect(() => {
    if (disciplineSelected && disciplineSelected !== null) {
      if (disciplineSelected && disciplineSelected.subjects?.length > 0) {
        setChecked([]);
        setAux([])
        setDisciplineAccount(disciplineSelected.subjects?.length)
        disciplineSelected.subjects.forEach(dis => {
          setChecked(old => [...old, dis.id])
          setAux(old => [...old, dis.id])
        });
      }
    }
  }, [disciplineSelected, Change, disciplineAccount])

  useEffect(() => {
    const findAllDiscipline = async () => {
      try {
        const { url, options } = GET_DISCIPLINE(page, 5, '', token);
        const response = await fetch(url, options);
        const result = await response.json();
        setDiscipline(result.data);
        console.log({a: result.data});
        setTotalAccount(result.meta.total)
      } catch (error) {
        // setError(error.message)
      }
    }
    findAllDiscipline();
  }, [page]);
  

  const joinDisciplineInCourse = async () => {
    setLoading(true)
    let subject_ids = []
    let subject_ids_remove = []
    
    checkedToRemove.forEach(el => {
      if (Aux.indexOf(el) !== -1) subject_ids_remove.push(el)
    });

    checked.forEach(el => {
      if (Aux.indexOf(el) === -1) subject_ids.push(el)
    });

    if(subject_ids_remove.length > 0) {
      await deleteDisciplineInCourse(subject_ids_remove)
    }


    const response = await courseService.createJoinCourseOfDiscipline({ course_id: courseId, subject_ids }, token)

    if (response instanceof Error || !response || response.message) {
      toast.error(response.message)
      setLoading(false);
    } else {
      // console.log(response.message);
      toast.success('disciplina(s) adiciona(s) com sucesso!', {
        position: 'top-right',
        className: 'custom-toast',
      })
      setChange(old => !old)
      setLoading(false);
    }
  }

const deleteDisciplineInCourse = async (subject_ids) => {
    
    const response = await courseService.deleteJoinCourseOfDiscipline({ course_id: courseId, subject_ids })

    if (response instanceof Error || !response) {
      console.log(response);
      
      toast.error(response.message)
      setLoading(false);
    } else {
      console.log(response);
      toast.error('disciplina(s) removidas(s) com sucesso!', {
        position: 'top-right',
        className: 'custom-toast',
      })
      setChange(old => !old)
      setLoading(false);
    }
  }

  return (
    <MenuDrawer page={`Dados Relacionados ao Curso`}>
      <Head title='Info Course' />
      <section className={styles.wrapper}>
        <article className={styles.description}>
          <h1 className='title'>{course && course?.name}</h1>
          <p style={{ marginTop: '1rem' }}>{course?.description}</p>
        </article>
        <article className={styles.datas}>
          <div className={styles.blockCards}>
            <CardMinimalCourseInfo account="6" description="Turmas Existentes" className={`${styles.card} ${styles.turma}`} colorD='#333' />
            <CardMinimalCourseInfo account={disciplineAccount} description="Disciplinas do curso" className={styles.card} colorD='#333' />
          </div>
          <div className={styles.blockDisciplinas}>
            <h3>Disciplinas</h3>
            <div>
              {discipline.length > 0 && discipline.map((dis) => (
                <ListItem key={dis.id} disablePadding
                  secondaryAction={<IconButton edge="end" aria-label="comments" />}
                >
                  <ListItemButton role={undefined} onClick={handleToggle(dis.id)} dense>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked.indexOf(dis.id) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': `checkbox-list-label-${dis.id}` }}
                      />
                    </ListItemIcon>
                    <ListItemText id={`checkbox-list-label-${dis.name}`} primary={`${dis.name}`} sx={{ textWrap: 'nowrap', textOverflow: 'ellipsis' }} />
                  </ListItemButton>
                </ListItem>
              )
              )}
              <Box mt={1} display={'flex'} alignItems={'center'} >
                <Box display={'flex'} justifyContent={'space-around'} alignItems={'center'} flex={1}>
                  <Box display={'flex'} alignItems={'center'} >
                    <PnoWrap fontSize={12} color="grayText" text={'Pag.'} />
                    <PnoWrap color='primary' fontSize={12} text={page} />
                  </Box>
                  <Box
                    sx={{
                      opacity: page === 1 ? .2 : 1,
                      cursor: page === 1 ? undefined : 'pointer',
                      ':hover': {
                        color: page !== 1 ? palette.primary.main : undefined
                      }
                    }}
                    onClick={() => page > 1 && setPage(page - 1)}>&lt;</Box>
                  <Box
                    sx={{
                      opacity: page === Math.ceil(totalAccount / 5) ? .2 : 1,
                      cursor: page === Math.ceil(totalAccount / 5) ? undefined : 'pointer',
                      ':hover': {
                        color: page <= totalAccount / 5 ? palette.primary.main : undefined
                      }
                    }} onClick={() => { page <= Math.ceil(totalAccount / 5) && setPage(page + 1) }}
                  >&gt;</Box>
                </Box>
                <Button onClick={() => joinDisciplineInCourse()} startIcon={loading ? <CircularProgress size={'1rem'} /> : undefined}>
                  Actualizar
                </Button>
              </Box>
            </div>
          </div>
        </article>
      </section>
    </MenuDrawer>
  )
}
