import React, { useMemo } from 'react'
import { MenuDrawer } from '../../components'
import { Environment, Head } from '../../shared'
import {
    Box, LinearProgress, Pagination,
    Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow,
    Typography, useTheme
} from '@mui/material'

import { Link, useSearchParams } from 'react-router-dom';
import { schoolService } from '../../shared/services';
import { useAuthContext } from '../../shared/hooks';
import { toast } from 'react-toastify';

export function Escola() {
    const { palette } = useTheme();
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [totalAccount, setTotalAccount] = React.useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const { token } = useAuthContext()
    const [Change, setChange] = React.useState(false);

    const search = useMemo(() => {
        return searchParams?.get('search') || ''
    }, [searchParams])

    const page = useMemo(() => {
        return searchParams?.get('page') || 1
    }, [searchParams])

    const findAllSchools = async () => {
        try {
            setLoading(true);
            setError(null);
            const { url, options } = schoolService.GET_school(page, Environment.ROW_LIMIT, search ?? '', token ?? '')
            const response = await fetch(url, options)
            const result = await response.json();
            setData(result.data);
            setTotalAccount(result.meta.total)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false);
        }
    }

    React.useEffect(() => {
        findAllSchools();
    }, [search, page, Change])

    const DeleteSchool = async (id) => {
        setLoading(true);
        setError(null);
        const response = await schoolService.DeleteOne(id, token);
        if (response instanceof Error) {
            console.log(response)
            setError(response.message);
            toast.error(response.message)
            setLoading(false);
        } else {
            toast.error('eliminado com sucesso!', {
                position: 'top-right',
                className: 'custom-toast',
            })
            setChange(!Change)
            setLoading(false);
        }
    }


    return (
        <MenuDrawer page='Escolas'>
            <Head title='Escolas' />

            <Box component={Paper} py={2} m={2} flex={1}>
                <Box m={1} ml={2}>
                    <section className='bloco-pesquisa-botao'>
                        <div className='search'>
                            <input type='text' placeholder='Pesquisar'
                                onChange={(evt) => { setSearchParams({ search: evt.target.value }, { replace: true }) }}
                                value={search ?? ''} />
                            <button><i className='fas fa-search'></i></button>
                        </div>

                        <Link to='/academic/school/register' className='btn btn-add'>
                            Cadastrar Escola <i className='fas fa-plus ml-1'></i>
                        </Link>
                        <Link to='/academic/school/erased' className='btn btn-remove'>
                            Reciclagem <i className='fas fa-trash ml-1'></i>
                        </Link>
                    </section>
                </Box>
                <Box m={1} ml={2}>
                    <Box marginBottom={5}>
                        <Box p={1} display={'flex'} width={'auto'} justifyContent={'flex-end'}>
                            <TableContainer component={Paper} elevation={3} sx={{ flexGrow: 1 }} >
                                <Table sx={{ minWidth: 650 }} size='small' aria-label="a dense table">
                                    <TableHead sx={{ backgroundColor: `${palette.primary.main}`}}>
                                        <TableRow>
                                            <TableCell className='title-table text-center'>Nome</TableCell>
                                            <TableCell className='title-table text-center'>Director</TableCell>
                                            <TableCell className='title-table text-center'>Localização</TableCell>
                                            <TableCell className='title-table text-center'>Acções</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            data.length > 0 && data.map((item) => (
                                                <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell className='text-center'>{item.name}</TableCell>
                                                    <TableCell className='text-center'>{item.user.name}</TableCell>
                                                    <TableCell className='text-center'>{item.address}</TableCell>
                                                    <TableCell align="center">
                                                        <Box display={'flex'} gap={1} justifyContent={'center'}>
                                                            <Link className='btn-icon' to={`/academic/school/show/${item.id}`}>
                                                                <i className="fa-solid fa-eye"></i>
                                                            </Link>
                                                            <Link to={`/academic/school/update/${item.id}`}>
                                                                <Typography className='btn-icon btn-edit'>
                                                                    <i className="fa-solid fa-edit"></i>
                                                                </Typography>
                                                            </Link>
                                                            {/* <Typography className='btn-icon btn-edit' onClick={() => DeleteSchool(item.id)}>
                                                                <i className="fa-solid fa-trash"></i>
                                                            </Typography> */}
                                                            <Link className='btn-icon' to={`/academic/school/print/${item.id}`}>
                                                                <i className="fa-solid fa-print"></i>
                                                            </Link>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                        {loading && (
                                            <TableRow>
                                                <TableCell colSpan={10} align="center">
                                                    <LinearProgress color='secondary' />
                                                </TableCell>
                                            </TableRow>)
                                        }
                                    </TableBody>
                                    <TableFooter>
                                        {data.length === 0 && (
                                            <TableRow>
                                                <TableCell colSpan={12} align="center">
                                                    <Typography width={250}>Lista de Escolas vazia</Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        <TableRow>
                                            <TableCell colSpan={10} align="center" sx={{ pb: 2 }}>
                                                <Pagination
                                                    count={Math.ceil(totalAccount / Environment.ROW_LIMIT)}
                                                    page={Number(page)} onChange={(_, newPage) => { setSearchParams({ _, page: newPage.toString() }, { replace: true }) }}
                                                    color='primary'
                                                    size='small'
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </MenuDrawer>
    )
}
