import { PnoWrap } from '../paragraphy'
import styles from './Input.module.css'
import React from 'react'

const Input = ({ id, name, label, type, value, onChange, placeholder, classe, ...rest }) => {
    return (
        <div className={styles.wrapper}>
            <label htmlFor={id}><PnoWrap color='grayText' text={label} /></label>
            <input type={type} {...rest} id={id} name={name} placeholder={placeholder} className={styles.classe}
                value={value??''} onChange={onChange} />
        </div>
    )
}

export default Input
