export const RoutesList = [
    {
        title: 'Página Inicial',
        to: '/dash',
        rules: ['dashboard_TM'],
        mainIcon: 'fas fa-house',
        subMenu: undefined
    },
    {
        title: 'Gestão de Usuários',
        to: undefined,
        mainIcon: 'fas fa-users-cog',
        rules: [
            'student_TM', 
            'candidate_inscri_TM', 
            'candidate_selected_TM',
            'functionary_TM'
        ],
        subMenu: [
            {
                title: 'Alunos',
                rulessub: [
                'student_TM', 
                ],
                Icon: 'fas fa-user-graduate',
                    to: '/people/students'
                },
            {
                title: 'Cand. Inscritos',
                rulessub: [
                'candidate_inscri_TM', 
                ],
                Icon: 'fas fa-users',
                    to: '/people/students/enrolled'
                },
            {
                title: 'Cand. Selecionados',
                rulessub: [
                'candidate_selected_TM',
                ],
                Icon: 'fas fa-users',
                    to: '/people/students/selected'
                },
            {
                title: 'Funcionários',
                rulessub: [
                'functionary_TM'
                ],
                Icon: 'fas fa-user-tie',
                    to: '/people/functionary'
                },
            // {
            //     title: 'Resultado',
            //     Icon: 'fas fa-user-tie',
            //     to: '/people/response'
            // },
            {
                title: 'Perfis de Acesso',
                Icon: 'fas fa-user-gear',
                to: '/people/access',
                rulessub: ['dashboard_TM'],
            }
        ]
    },
    {
        title: 'Gestão Escolar',
        to: undefined,
        mainIcon: 'fas fa-school',
        rules: [
            'training_area_TM', 
            'school_TM', 
            'course_TM',
            'classes_TM',
            'discipline_TM',
            'curricular_grade_TM',
            'internal_location_TM'
        ],
        subMenu: [
            {
                title: 'Area de Formação',
                Icon: 'fas fa-school',
                rulessub: ['training_area_TM'],
                to: '/academic/training'
            },
            {
                title: 'Escolas',
                Icon: 'fas fa-school',
                rulessub: ['school_TM'],
                to: '/academic/school'
            },
            {
                title: 'Cursos',
                Icon: 'fa-solid fa-person-chalkboard',
                rulessub: ['course_TM'],
                to: '/academic/courses'
            },
            {
                title: 'Salas',
                Icon: 'fas fa-chalkboard-teacher',
                rulessub: ['classroom_TM'],
                to: '/academic/classroom'
            },
            {
                title: 'Turmas',
                Icon: 'fas fa-chalkboard-teacher',
                rulessub: ['classes_TM'],
                to: '/academic/classes'
            },
            {
                title: 'Disciplinas',
                Icon: 'fas fa-book',
                rulessub: ['discipline_TM'],
                to: '/academic/discipline'
            },
            {
                title: 'Grade Curricular',
                Icon: 'fa-solid fa-book-open',
                rulessub: ['curricular_grade_TM'],
                to: ''
            },
            {
                title: 'Localização Interna',
                Icon: 'fa-solid fa-map',
                rulessub: ['internal_location_TM'],
                to: ''
            }
        ]
    },
    {
        title: 'Gestão Pedagógica',
        to: undefined,
        mainIcon: 'fas fa-folder',
        rules: [
            'especial_exam_TM', 
            'recurse_TM', 
            'update_nota_TM',
            'list_nota_TM',
        ],
        subMenu: [
            {
                title: 'Exame Especial',
                Icon: 'fas fa-rectangle-list',
                rulessub: ['especial_exam_TM'],
                to: ''
            },
            {
                title: 'Exame Recurso',
                Icon: 'fas fa-rectangle-list',
                rulessub: ['recurse_TM'],
                to: ''
            },
            // {
            //     title: 'Folha de Prova',
            //     Icon: 'fas fa-file',
            //     to: ''
            // },
            {
                title: 'Melhoria de Nota',
                Icon: 'fas fa-file-excel',
                rulessub: ['update_nota_TM'],
                to: ''
            },
            {
                title: 'Mini Pauta',
                Icon: 'fas fa-file-excel',
                rulessub: ['list_nota_TM'],
                to: ''
            },
            {
                title: 'Pauta',
                Icon: 'fas fa-file-excel',
                rulessub: [''],
                to: '/academic/pauta'
            },
            
        ]
    },
    {
        title: 'Gestão Administrativa',
        to: undefined,
        mainIcon: 'fas fa-user',
        rules: [
            'frequency_declaration_TM',
            'frequency_nota_TM', 
            'transference_TM', 
            'certificate_TM',
            'register_TM',
            'confirm_register_TM',
            'term_frequency_TM',
            'uniform_TM',
            'publish_vag_TM'
        ],
        subMenu: [
            {
                title: 'Declaração de Frequência',
                Icon: 'fas fa-file-word',
                rulessub: ['frequency_declaration_TM'],
                to: ''
            },
            {
                title: 'Declaração com Notas',
                Icon: 'fas fa-file-excel',
                rulessub: ['frequency_nota_TM'],
                to: ''
            },
            {
                title: 'Transferência',
                Icon: 'fas fa-exchange-alt',
                rulessub: ['transference_TM'],
                to: ''
            },
            {
                title: 'Certificado',
                Icon: 'fas fa-file',
                rulessub: ['certificate_TM'],
                to: ''
            },
            {
                title: 'Matrícula',
                Icon: 'fa-solid fa-pen',
                rulessub: ['register_TM'],
                to: '/academic/register'
            },
            {
                title: 'Confirmação de Matrícula',
                Icon: 'fa-solid fa-edit',
                rulessub: ['confirm_register_TM'],
                to: '/academic/performance'
            },
            {
                title: 'Termo de Frequência',
                Icon: 'fas fa-file-code',
                rulessub: ['term_frequency_TM'],
                to: ''
            },
            {
                title: 'Uniforme Escolar',
                Icon: 'fas fa-user-tie',
                rulessub: ['uniform_TM'],
                to: ''
            },
            {
                title: 'Publicação de Vagas',
                Icon: 'fas fa-user-tie',
                rulessub: ['publish_vag_TM'],
                to: '/academic/publish'
            }
        ]
    },
    {
        title: 'Gestão de Frequência',
        to: undefined,
        mainIcon: 'fas fa-person-circle-check',
        rules: [
            'presence_TM',
            'fault_TM', 
            'justify_fault_TM', 
        ],
        subMenu: [
            {
                title: 'Presenças',
                Icon: 'fas fa-check',
                rulessub: ['presence_TM'],
                to: '/academic/school'
            },
            {
                title: 'Faltas',
                Icon: 'fas fa-remove',
                rulessub: ['fault_TM'],
                to: '/academic/school'
            },
            {
                title: 'Justificativo de Faltas',
                Icon: 'fas fa-user-cog',
                rulessub: ['justify_fault_TM'],
                to: '/academic/school'
            },
        ]
    },
    {
        title: 'Relatórios e Estatísticos',
        to: undefined,
        mainIcon: 'fas fa-chart-bar',
        subMenu: [
            {
                title: 'Alunos Inscritos',
                Icon: 'fa fa-address-card',
                to: ''
            },
            {
                title: 'Alunos selecionados',
                Icon: 'fa fa-person-chalkboard',
                to: ''
            },
            {
                title: 'Lista de Funcionários',
                Icon: 'fa fa-user-shield',
                to: ''
            },
        ],
    },
    {
        title: 'Gestão Financeira',
        to: undefined,
        mainIcon: 'fas fa-headset',
        rules: [
            'energy_water_TM',
        ],
        subMenu: [
            {
                title: 'Energia e Água',
                Icon: 'fa-solid fa-building',
                rulessub: ['energy_water_TM'],
                to: ''
            }
        ]
    },
    {
        title: 'Suporte Técnico',
        to: undefined,
        mainIcon: 'fas fa-headset',
        rules: [
            'school_support_TM',
            'system_support_TM'
        ],
        subMenu: [
            {
                title: 'Suporte Escolar',
                Icon: 'fas fa-phone',
                rulessub: ['school_support_TM'],
                to: ''
            },
            {
                title: 'Suporte de Sistema',
                Icon: 'fas fa-tools',
                rulessub: ['system_support_TM'],
                to: ''
            },
        ]
    },
]