// styles
import styles from './add_escola.module.css';
// dependencies
import * as yup from 'yup';
import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Container, FormControl, InputLabel, MenuItem, Paper, Select } from '@mui/material'
import { styled } from '@mui/material/styles';
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Input } from '../form';
import { functionaryServices, Head, inscription_services, schoolService, usersServices } from '../../shared'
import { MenuDrawer } from '../Dashboard';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from '../../shared/hooks';
import { toast } from 'react-toastify';

const schema = yup.object().shape({
    name: yup.string().required().min(2),
    school_type_id: yup.number().required(),
    level_education_id: yup.number().optional(),
    email: yup.string().required(),
    province_id: yup.number().required().moreThan(0),
    municipality_id: yup.number().required(),
    decree_creation: yup.string().notRequired(),
    reference_point: yup.string().optional(),
    school_number: yup.number().optional(),
    location_id: yup.number().optional().moreThan(0),
    public_school_id: yup.number().optional(),
})

export function AddAndUpdateEsola() {
    const [isUpdate, setIsUpdate] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const [countries, setCountries] = useState([])
    const { control, handleSubmit, formState: { errors }, setValue, watch } = useForm({
        resolver: yupResolver(schema), defaultValues: {
            name: '',
            school_type_id: undefined,
            level_education_id: undefined,
            province_id: undefined,
            municipality_id: undefined,
            reference_point: '',
            email: '',
            decree_creation: null,
            school_number: undefined,
            location_id: undefined,
            public_school_id: undefined
        }
    })

    const schoolTypeChange = watch('school_type_id');
    const [schools, setsShools] = useState(null);
    const [level, setLevel] = useState([]);
    const [schoolType, setSchoolType] = useState([]);
    const [municipe, setMunicipe] = useState([]);
    const [Loading, setLoading] = useState(false);
    const [schoolPublic, setSchoolPublic] = React.useState([]);
    const [province, setProvince] = useState([]);
    // const [Users, setUsers] = useState([]);
    const { token } = useAuthContext()

    const getSchoolType = async () => {
        try {
            const response = await schoolService.listSchoolType(token);
            setSchoolType(response.data)
        } catch (error) {
            console.log({ error });
        }
    }

    const getSchoolMunicipe = async () => {
        try {
            const response = await schoolService.listSchoolMunicipe(token);
            setMunicipe(response.data)
        } catch (error) {
            console.log({ error });
        }
    }

    const getSchoolLevel = async () => {
        try {
            const response = await schoolService.listSchoolLevel(token);
            setLevel(response.data)
        } catch (error) {
            console.log({ error });
        }
    }

    const findAllSchools = async () => {
        try {
            const { url, options } = schoolService.GET_school(undefined, 100000000, 'publico', token ?? '')
            const response = await fetch(url, options);
            const result = await response.json();
            setSchoolPublic(result.data);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (id && typeof Number(id) === 'number') {
            setIsUpdate(true)
            schoolService.getOneSchool(id).then((data) => {
                setsShools(data.data);
            }).catch(err => {
                console.log({ err });
            })
        }
        getSchoolType();
        getSchoolMunicipe();
        getSchoolLevel();
        findAllSchools();
    }, []);

    useEffect(() => {
        if (schools) {
            setValue('province_id', schools?.['Provincia angolana']?.id);
            setValue('name', schools.name);
            setValue('municipality_id', schools.municipality_id);
            setValue('level_education_id', schools.level_education_id);
            setValue('school_type_id', schools.school_type_id);
            setValue('email', schools.email);
            setValue('decree_creation', schools.decree_creation);
            setValue('school_number', schools.school_number);
            setValue('public_school_id', schools.public_school_id);
            setValue('reference_point', schools.reference_point);
        }
    }, [schools]);

    const submitData = async (data) => {
        setLoading(true)
        delete data.country_id;
        try {
            const response = await schoolService.POST_school({ ...data, sort_order: 1, code: '1' },token);
            console.log({ response });
            if (response.error) {
                return toast.error(response.error.message || 'Problemas ao cadastrar Escola');
            }
            if (response.errors && response.message) {
                return toast.error(response.message || 'Problemas ao cadastrar Escola');
            }
            toast.success('Escola cadastrada com sucesso')
            navigate('/academic/school')
        } catch (error) {
            return toast.success('Problemas ao cadastrar a Escola');
        } finally {
            setLoading(false)
        }
    }

    const submitUpdate = async (data) => {
        setLoading(true)
        delete data.country_id;
        try {
            const response = await schoolService.updateSchool(id, data, token);
            if (response.error) {
                return toast.success('Problemas ao actualizar a Escola');
            }
            console.log({ response });
            if (response.data) {
                toast.success('Escola actualizada com sucesso')
                return navigate('/academic/school')
            }
            return toast.error('Problemas ao actualizar a Escola');
        } catch (error) {
            console.error({ error })
            return toast.error('Problemas ao actualizar a Escola');
        } finally {
            setLoading(false)
        }
    }

    const getCountries = async () => {
        try {
            const { data } = await inscription_services.getAllCountry();
            setCountries(data);
        } catch (error) {
            console.log(error);
        }
    }

    // const getUsers = async () => {
    //     try {
    //         const { data } = await usersServices.getAll(token);
    //         setUsers(data ?? [])
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    const handleSelectCountry = async (id) => {
        try {
            const { data } = await inscription_services.getProvinceForCountry(6);
            console.log({ data }, ' \n province');
            setProvince(data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        handleSelectCountry()
        getCountries()
        // getUsers();
    }, [])

    return (
        <MenuDrawer page='Escolas'>
            <Head title={id ? 'Actualizar dados do Disciplina' : 'Cadastra Escola'} />
            <Box component={Paper} m={2} borderRadius={2}>
                <Box p={1} >
                    <Box component={Container} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                        {!id
                            ? (
                                <div style={{ marginBottom: '1.5rem', marginTop: '1rem' }}>
                                    <h1 className='title'>Cadastro</h1>
                                    <p>Preencha todos os campos para efectuar o cadastro</p>
                                </div>
                            )
                            : (
                                <div style={{ marginBottom: '1.5rem', marginTop: '1rem' }}>
                                    <h1 className='title'>Actualizar</h1>
                                    <p>Edite todos os dados desejado para que a actualização seja bem sucedida</p>
                                </div>
                            )
                        }
                        <Box component={'form'} noValidate encType='multipart/form-data' onSubmit={!!isUpdate ? handleSubmit(submitUpdate) : handleSubmit(submitData)}>
                            <div className={styles.row}>
                                <div className={styles.blockOne}>
                                    <InputLabel className='label'>Nome</InputLabel>
                                    <Controller
                                        control={control}
                                        name='name'
                                        rules={{ required: 'Campo obrigatorio' }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                className={styles.input}
                                                margin="normal"
                                                onChange={onChange}
                                                value={value}
                                                placeholder={"Digite o nome da Escola"}
                                                error={!!errors.name}
                                                helperText={errors.name ? errors.name.message : ''}
                                            />
                                        )}
                                    />
                                </div>
                                <div className={styles.blockOne}>
                                    <InputLabel className='label'>Email</InputLabel>
                                    <Controller
                                        control={control}
                                        name='email'
                                        rules={{ required: 'Campo obrigatorio' }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                className={styles.input}
                                                type={'email'}
                                                margin="normal"
                                                onChange={onChange}
                                                value={value}
                                                placeholder={"Digite o email"}
                                                error={!!errors.name}
                                                helperText={errors.email ? errors.email.message : ''}
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className={styles.row}>
                                <div className={styles.blockOne}>
                                    <Controller
                                        control={control}
                                        name='school_type_id'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <>
                                                <InputLabel className='label'>Tipo de escola</InputLabel>
                                                <FormControl fullWidth
                                                    margin="normal">
                                                    <InputLabel id='demo-simple-select-label' >Selecione</InputLabel>
                                                    <Select
                                                        className={styles.input}
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        fullWidth
                                                        onChange={onChange} value={value ?? ''}
                                                        color='primary'
                                                        error={!!errors.school_type_id}
                                                        label='Selecione'
                                                    >
                                                        {schoolType.length > 0 && schoolType.map((el) => (<MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>))}
                                                    </Select>
                                                </FormControl>
                                            </>
                                        )}
                                    />
                                </div>
                                <div className={styles.blockTwo}>
                                    <Controller
                                        control={control}
                                        name='province_id'
                                        rules={{ required: 'Campo obrigatorio' }}
                                        render={({ field: { value, onChange } }) => (
                                            <>
                                                <InputLabel className='label'>Província</InputLabel>
                                                <FormControl fullWidth
                                                    margin="normal">
                                                    <InputLabel id='demo-simple-select-label' >Selecione</InputLabel>
                                                    <Select
                                                        className={styles.input}
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        fullWidth
                                                        onChange={onChange} value={value ?? ''}
                                                        color='primary'
                                                        error={!!errors.years}
                                                        label='Selecione'
                                                    >
                                                        {province.length > 0 && province.map((el) => (<MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>))}
                                                    </Select>
                                                </FormControl>
                                            </>
                                        )}
                                    />
                                </div>
                            </div>

                            <div className={styles.row}>
                                <div className={styles.blockOne}>
                                    <Controller
                                        control={control}
                                        name='municipality_id'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <>
                                                <InputLabel className='label'>Município</InputLabel>
                                                <FormControl fullWidth
                                                    margin="normal">
                                                    <InputLabel id='demo-simple-select-label' >Selecione</InputLabel>
                                                    <Select
                                                        className={styles.input}
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        fullWidth
                                                        onChange={onChange} value={value ?? ''}
                                                        color='primary'
                                                        error={!!errors.municipality_id}
                                                        label='Selecione'
                                                    >
                                                        {municipe.length > 0 && municipe.map((el) => (<MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>))}
                                                    </Select>
                                                </FormControl>
                                            </>
                                        )}
                                    />
                                </div>
                                <div className={styles.blockTwo}>
                                    <Controller
                                        control={control}
                                        name='level_education_id'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <>
                                                <InputLabel className='label'>Nivel de Ensino</InputLabel>
                                                <FormControl fullWidth
                                                    margin="normal">
                                                    <InputLabel id='demo-simple-select-label' >Selecione</InputLabel>
                                                    <Select
                                                        className={styles.input}
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        fullWidth
                                                        onChange={onChange} value={value ?? ''}
                                                        color='primary'
                                                        error={!!errors.level_education_id}
                                                        label='Selecione'
                                                    >
                                                        {level.length > 0 && level.map((el) => (<MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>))}
                                                    </Select>
                                                </FormControl>
                                            </>
                                        )}
                                    />
                                </div>
                            </div>

                            {schoolTypeChange !== 3 &&
                                <div className={styles.row}>
                                    <div className={styles.blockOne}>
                                        <InputLabel className='label'>Decreto</InputLabel>
                                        <Controller
                                            control={control}
                                            name='decree_creation'
                                            render={({ field: { value, onChange } }) => (
                                                <Input
                                                    className={styles.input}
                                                    margin="normal"
                                                    onChange={onChange}
                                                    value={value ?? ''}
                                                    placeholder={"Digite o nome do funcionário"}
                                                    color='primary'
                                                    error={!!errors.decree_creation}
                                                    helperText={errors.decree_creation ? errors.decree_creation.message : ''}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className={styles.blockTwo}>
                                        <InputLabel className='label'>Nº da Escola</InputLabel>
                                        <Controller
                                            control={control}
                                            name='school_number'
                                            rules={{
                                                required: 'Campo obrigatorio'
                                            }}
                                            render={({ field: { value, onChange } }) => (
                                                <Input
                                                    className={styles.input}
                                                    margin="normal"
                                                    onChange={onChange}
                                                    value={value?.toString() ?? ''}
                                                    placeholder={"Digite o nome do funcionário"}
                                                    color='primary'
                                                    error={!!errors.school_number}
                                                    helperText={errors.school_number ? errors.school_number.message : ''}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            }

                            <div className={styles.row}>
                                <div className={styles.blockOne}>
                                    <InputLabel className='label'>Ponto de Referência</InputLabel>
                                    <Controller
                                        control={control}
                                        name='reference_point'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                className={styles.input}
                                                margin="normal"
                                                onChange={onChange}
                                                value={value}
                                                placeholder={"Digite o nome do funcionário"}
                                                color='primary'
                                                error={!!errors.reference_point}
                                                helperText={errors.reference_point ? errors.reference_point.message : ''}
                                            />
                                        )}
                                    />
                                </div>
                                {schoolTypeChange === 3 &&
                                    <div className={styles.blockTwo}>
                                        <Controller
                                            control={control}
                                            name='public_school_id'
                                            rules={{
                                                required: 'Campo obrigatorio'
                                            }}
                                            render={({ field: { value, onChange } }) => (
                                                <>
                                                    <InputLabel className='label'>Escola Publica a Referenciar</InputLabel>
                                                    <FormControl fullWidth
                                                        margin="normal">
                                                        <InputLabel id='demo-simple-select-label' >Selecione</InputLabel>
                                                        <Select
                                                            className={styles.input}
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            fullWidth
                                                            onChange={onChange} value={value ?? ''}
                                                            color='primary'
                                                            error={!!errors.public_school_id}
                                                            label='Selecione'
                                                        >
                                                            {schoolPublic.length > 0 && schoolPublic.map((el) => (<MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>))}
                                                        </Select>
                                                    </FormControl>
                                                </>
                                            )}
                                        />
                                    </div>
                                }
                            </div>
                            <Box>
                                {id ?
                                    <Box className={styles.row}>
                                        <Button
                                            variant='contained'
                                            sx={{ mb: 2, py: 2 }}
                                            color='primary'
                                            fullWidth
                                            type='submit'
                                            onSubmit={handleSubmit(submitUpdate)}
                                            endIcon={Loading ? <CircularProgress size={'1rem'} color='primary' /> : undefined}
                                        >
                                            Actualizar
                                        </Button>
                                    </Box>
                                    :
                                    <Box sx={{ my: 2 }}>
                                        <Button
                                            sx={{ mb: 2, py: 2, background: '#E19E06', color: '#333' }}
                                            variant='contained'
                                            fullWidth
                                            type='submit'
                                            onSubmit={handleSubmit(submitData)}
                                            disabled={Loading}
                                            endIcon={Loading ? <CircularProgress size={'1rem'} color='primary' /> : undefined}
                                        >
                                            Guardar
                                        </Button>
                                    </Box>
                                }
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </MenuDrawer>
    )
}