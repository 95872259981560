import styles from './Inscription.module.css';
// import styless from '../../components/formComponents/Select.module.css';
import React, { useEffect, useState } from 'react'
import { Box, FormControl, FormLabel, Grid, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { PnoWrap } from '../../components';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocalData } from '../../shared/hooks';
import { GET_COURSES1, Head, inscription_services, schoolService } from '../../shared';
import { toast } from 'react-toastify';
import PdfPreview from '../../components/pdf/PdfRender';
import { API } from '../../shared/services/api';
// my components
import Header from '../../components/Header';
import Select from '../../components/formComponents/Select'
import Input from '../../components/formComponents/Input'
import ModalDownPdf from './ModalPdfGet';


const schema = yup.object().shape({
    document_number: yup.string().required('numero do documento de identidade obrigatório'),
    document_type_id: yup.number().required('Informe a qual documento pertence o número anteriormente passado.'),
    course_id: yup.number().required('Informe o curso na qual pretende concorrer.'),
    alternative_course_id: yup.number().required('Informe o curso alternativo na qual pretende concorrer caso não haja vaga no primeiro curso.'),
    period_id: yup.number().required('Informe o periodo.'),
    province_id: yup.number().required('Província é obrigatorio'),
    location_id: yup.number().required('Escola é obrigatorio'),
    training_area_id: yup.number().required('Area de formação é obrigatorio'),
    document_file: yup.mixed()
    /* 
    .test("required", "Você deve selecionar um arquivo", (value) => {
      return value && value.length > 0;
    }) */,
    /* .test("fileSize", "O arquivo é muito grande", (value) => {
      return value && value[0] && value[0].size <= 2000000; // Limite de 2MB
    }) */
    // .test("fileType", "Apenas arquivos JPEG/PNG são permitidos", (value) => {
    //   return value && value[0] && (value[0].type === "image/jpeg" || value[0].type === "image/png");
    // }),

    certification_file: yup
        .mixed()/* 
    .test("required", "Você deve selecionar um arquivo", (value) => {
      return value && value.length > 0;
    }) */
    /* .test("fileSize", "O arquivo é muito grande", (value) => {
      return value && value[0] && value[0].size <= 2000000; // Limite de 2MB
    }) */
    // .test("fileType", "Apenas arquivos JPEG/PNG são permitidos", (value) => {
    //   return value && value[0] && (value[0].type === "image/jpeg" || value[0].type === "image/png" || value[0].type === "application/pdf");
    // }),
})

export const FormTwo = () => {
    const { control, handleSubmit, formState: { errors }, setValue, getValues, watch } = useForm({
        resolver: yupResolver(schema), defaultValues: {
            document_number: '',
            document_type_id: undefined,
            course_id: undefined,
            alternative_course_id: undefined,
            period_id: undefined,
        }
    });

    const schoolsSelected = watch('location_id');
    const [user, setUser] = useState(null);
    const [Courses, setCourses] = useState([]);
    const [Courses_alternative, setCoursesAlternative] = useState([]);
    const [period, setPeriod] = useState([]);
    const [docsType, setDocsType] = useState([]);
    const params = useParams();
    const [Loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [link, setLink] = useState('');

    const navigate = useNavigate()

    const { setCandidateLocalData, getCandidateLocalData, cleanLocalData } = useLocalData();

    const [selectedFile, setSelectedFile] = useState(null);
    const [fileURL, setFileURL] = useState(null);
    const [fileURL1, setFileURL1] = useState(null);
    const [Province, setProvince] = useState([]);
    const [school, setSchool] = useState([]);
    const [trainingArea, setTrainingArea] = useState([]);

    const handleFileChange = (event, control = 1) => {
        const file = event.target.files[0];

        setSelectedFile(file);

        // Cria uma URL temporária para visualização do arquivo
        const fileUrl = URL.createObjectURL(file);
        if (control === 1) {
            setFileURL(fileUrl);
        } else {
            setFileURL1(fileUrl);
        }
    };

    const getAllProvince = async () => {
        try {
            const { data } = await inscription_services.getProvinceForCountry(6);

            setProvince(data)
        } catch (error) {
            console.log(error);

        }
    }

    useEffect(() => {
        getAllProvince()
    }, [])


    useEffect(() => {
        const findAllCourses = async () => {
            try {
                setLoading(true);
                const { url, options } = GET_COURSES1(getValues('location_id'));
                const response = await fetch(url, options);
                const result = await response.json();
                
                setCourses(result.data);
            } catch (error) {
                if (error.message === 'Failed to fetch')
                    toast.error('Problemas de conexão com a internet!')
                toast.error(error.message)

            } finally {
                setLoading(false);
            }
        }
        findAllCourses();
    }, [schoolsSelected]);

    useEffect(() => {
        const findAllCourses = async () => {
            try {
                setLoading(true);
                const { url, options } = GET_COURSES1(getValues('location_id'), getValues('training_area_id'));
                const response = await fetch(url, options);
                const result = await response.json();
                
                setCoursesAlternative(result.data);
            } catch (error) {
                if (error.message === 'Failed to fetch')
                    toast.error('Problemas de conexão com a internet!')
                toast.error(error.message)

            } finally {
                setLoading(false);
            }
        }
        findAllCourses();
    }, [schoolsSelected]);

    useEffect(() => {
        const dataLocal = getCandidateLocalData()
        let aux = 10;
        if (!user && JSON.parse(dataLocal)) {
            setUser(JSON.parse(dataLocal))
        }

        if (user) {
            setValue('document_number', user.nif);
            setValue('document_type_id', user.doc)
        }

        if(!user && aux === 9 ) {
            navigate('/')
        }

        aux -= 1;
    }, [user]);

    const getCountries = async () => {
        try {
            const { data } = await inscription_services.getAllPeriod();
            setPeriod(data)
        } catch (error) {
            console.log(error);

        }
    }


    const findAllSchools = async (filter) => {
        try {
            const { url, options } = schoolService.GET_school_filter(filter)
            const response = await fetch(url, options)
            const result = await response.json();
            setSchool(result.data);
        } catch (error) {
            console.log({ error });
        }
    }

    const getDocsType = async () => {
        try {
            const { data } = await inscription_services.getDocsType();
            setDocsType(data)
        } catch (error) {
            console.log(error);
        }
    }
    
    useEffect(() => {
        getCountries()
        getDocsType()
    }, [])

    const handleSubmitInscription = async (data) => {
        if (!user) {
            toast.alert('Precisa passar seu dados pessoais');
            return navigate('/')
        }

        if (!data.document_file || !data.certification_file) return alert('Precisa passar ambos os documentos!')

        setLoading(true)
        const formDate = new FormData();

        formDate.append('name', user.full_name);
        formDate.append('birthdate', user.birth_date);
        formDate.append('gender', user.gender);
        formDate.append('natural_from', user.natural_from);
        formDate.append('document_type_id', data.document_type_id);
        formDate.append('location_id', /* data.location_id */ 1);
        formDate.append('document_number', user.nif);
        formDate.append('document_file', data.document_file);
        formDate.append('certification_file', data.certification_file);
        formDate.append('course_id', data.course_id);
        formDate.append('alternative_course_id', data.alternative_course_id);
        formDate.append('period_id', data.period_id);
        formDate.append('final_score', user.final_score);
        formDate.append('last_school_type_id', user.last_school_type_id);
        formDate.append('last_school_name', user.last_school_name);
        formDate.append('last_school_country_id', user.last_school_country_id);
        formDate.append('last_school_province_id', user.last_school_province_id);
        formDate.append('last_school_city', user.last_school_city);
        formDate.append('last_school_end_year', user.last_school_end_year);
        formDate.append('debilities', user.debilities);
        formDate.append('location_id', data.location_id);
        // formDate.append('training_area_id', data.training_area_id);  

        
        try {
            const result = await fetch(`${API}/applications`, {
                method: 'POST',
                body: formDate,
            });
            
            const response = await result.json()
            console.log({formDate, response});
            
            if (response.data) {
                setOpenModal(true)
                setLink(response.data.pdf_link)
                toast.success(user.full_name + ' sua Inscrição foi concluída com sucesso')
                cleanLocalData()
            } 
            if (response.certification_file) {
                // setOpenModal(true)
                // setLink(response.data.pdf_link)
                toast.error(user.full_name + ' ' + response.certification_file)
                // cleanLocalData()
            }
            
            if (response.error) {
                // setOpenModal(true)
                // setLink(response.data.pdf_link)
                toast.error(user.full_name + ' ' + response.error)
                // cleanLocalData()
            }

            if (!response.sucess) return toast.error(response.message)
        } catch (error) {
            console.log({ error });

        } finally {
            setLoading(false)
        }
    }

    const ChangeProvince = async (Province_id) => {
        setValue('province_id', Province_id)
        await findAllSchools(Province_id)
    }

    const setAreaFormation = (id) => {
        setTrainingArea([])
        Courses.forEach(el => {
            if(el.id === Number(id)) {
                setTrainingArea([el?.training_area])
                setValue('training_area_id', el?.training_area?.id)
            }
        });
    }

    return (
        <section style={{ background: '#fff' }}>
            <Header />
            {openModal && <ModalDownPdf setModal={setOpenModal} link={link} />}
            <Head title='Ficha de Inscrição' />
            <section className={styles.inscription}>
                <div className={styles.card}>
                    <p className={styles.paragraph}>
                        Preencha todos os campos para que a sua inscrição seja bem sucedida
                    </p>
                    <div className={styles.blockForm}>
                        <Box component={'form'} noValidate encType='multipart/form-data' onSubmit={handleSubmit(handleSubmitInscription)}>
                            <div className={styles.row}>
                                <div className={styles.blockInput}>
                                    <Controller
                                        control={control}
                                        name='province_id'
                                        render={({ field: { value, onChange } }) => (
                                            <Box display={'flex'} flexDirection={'column'} style={{ width: '100%' }}>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={value ?? ''}
                                                    label="Província em que se está a candidatar"
                                                    onChange={(e) => ChangeProvince(e.target.value)}
                                                                                                        values={Province}
                                                />
                                                {errors.province_id?.message &&
                                                    <Typography color={'red'} fontSize={12}>
                                                        {errors.province_id?.message}
                                                    </Typography>
                                                }
                                            </Box>
                                        )}
                                    />
                                </div>
                                <div className={styles.blockInput}>
                                    <Controller
                                        control={control}
                                        name='location_id'
                                        render={({ field: { value, onChange } }) => (
                                            <Box display={'flex'} flexDirection={'column'} style={{ width: '100%' }}>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={value ?? ''}
                                                    label="Selecione a Escola"
                                                    onChange={onChange}
                                                                                                        values={school}
                                                />
                                                {errors.location_id?.message &&
                                                    <Typography color={'red'} fontSize={12}>
                                                        {errors.location_id?.message}
                                                    </Typography>
                                                }
                                            </Box>
                                        )}
                                    />
                                </div>
                                
                            </div>
                            {/* fim de linha */}

                            <div className={styles.row}>
                                <div className={styles.blockInput}>
                                    <Controller
                                        control={control}
                                        name='course_id'
                                        render={({ field: { value, onChange } }) => (
                                            <Box display={'flex'} flexDirection={'column'} style={{ width: '100%' }}>
                                                <FormControl >
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        label='Curso Principal'
                                                        id="demo-simple-select"
                                                        value={value ?? ''}
                                                        onChange={(e) => {onChange(e.target.value); setAreaFormation(e.target.value)}}
                                                        
                                                        values={Courses}
                                                    >
                                                    </Select>
                                                    {errors.course_id?.message &&
                                                        <Typography color={'red'} fontSize={12}>
                                                            {errors.course_id?.message}
                                                        </Typography>
                                                    }
                                                </FormControl>
                                            </Box>
                                        )}
                                    />
                                </div>
                                <div className={styles.blockInput}>
                                    <Controller
                                        control={control}
                                        name='training_area_id'
                                        render={({ field: { value, onChange } }) => (
                                            <Box display={'flex'} flexDirection={'column'} style={{ width: '100%' }}>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={value ?? ''}
                                                    label="Selecione a área de formação"
                                                    onChange={onChange}
                                                                                                        values={trainingArea}
                                                />
                                                {errors.training_area_id?.message &&
                                                    <Typography color={'red'} fontSize={12}>
                                                        {errors.training_area_id?.message}
                                                    </Typography>
                                                }
                                            </Box>
                                        )}
                                    />
                                </div>
                            </div>
                            {/* fim de linha */}

                            <div className={styles.row}>
                                <div className={styles.blockInput}>
                                    <Controller
                                        control={control}
                                        name='alternative_course_id'
                                        render={({ field: { value, onChange } }) => (
                                            <Box display={'flex'} flexDirection={'column'} style={{ width: '100%' }}>
                                                <FormControl >
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={value ?? ''}
                                                        label="Curso Alternativo"
                                                        onChange={onChange}
                                                        
                                                        values={Courses_alternative}
                                                    >
                                                    </Select>
                                                    {errors.alternative_course_id?.message &&
                                                        <Typography color={'red'} fontSize={12}>
                                                            {errors.alternative_course_id?.message}
                                                        </Typography>
                                                    }
                                                </FormControl>
                                            </Box>
                                        )}
                                    />
                                </div>
                                <div className={styles.blockInput}>
                                    <Controller
                                        control={control}
                                        name='period_id'
                                        render={({ field: { value, onChange } }) => (
                                            <Box display={'flex'} flexDirection={'column'} style={{ width: '100%' }}>
                                                <FormControl >
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={value ?? ''}
                                                        label="Periodo"
                                                        onChange={onChange}
                                                        
                                                        values={period}
                                                    > </Select>
                                                    {errors.period_id?.message &&
                                                        <Typography color={'red'} fontSize={12}>
                                                            {errors.period_id?.message}
                                                        </Typography>
                                                    }
                                                </FormControl>
                                            </Box>
                                        )}
                                    />
                                </div>
                            </div>
                            {/* fim de linha */}

                            <Grid container spacing={2}>
                                {/* <Grid item xs={12} sm={12} md={3} >
                                    <Box style={{ width: '100%' }}>
                                        <Controller
                                            control={control}
                                            name='province_id'
                                            render={({ field: { value, onChange } }) => (
                                                <Box display={'flex'} flexDirection={'column'} style={{ width: '100%' }}>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={value ?? ''}
                                                        label="Selecione a Província"
                                                        onChange={(e) => ChangeProvince(e.target.value)}
                                                                                                                values={Province}
                                                    />
                                                    {errors.province_id?.message &&
                                                        <Typography color={'red'} fontSize={12}>
                                                            {errors.province_id?.message}
                                                        </Typography>
                                                    }
                                                </Box>
                                            )}
                                        />
                                    </Box>
                                </Grid> */}
                                <Grid item xs={12} sm={12} md={3} >
                                    {/* <Box style={{ width: '100%' }}>
                                        <Controller
                                            control={control}
                                            name='location_id'
                                            render={({ field: { value, onChange } }) => (
                                                <Box display={'flex'} flexDirection={'column'} style={{ width: '100%' }}>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={value ?? ''}
                                                        label="Selecione a Escola"
                                                        onChange={onChange}
                                                                                                                values={school}
                                                    />
                                                    {errors.location_id?.message &&
                                                        <Typography color={'red'} fontSize={12}>
                                                            {errors.location_id?.message}
                                                        </Typography>
                                                    }
                                                </Box>
                                            )}
                                        />
                                    </Box> */}
                                </Grid>

                                {/* <Grid item xs={12} sm={12} md={3} >
                                    <Box style={{ width: '100%' }}>
                                        <Controller
                                            control={control}
                                            name='course_id'
                                            render={({ field: { value, onChange } }) => (
                                                <Box display={'flex'} flexDirection={'column'} style={{ width: '100%' }}>
                                                    <FormControl >
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            label='Curso Principal'
                                                            id="demo-simple-select"
                                                            value={value ?? ''}
                                                            onChange={onChange}
                                                            
                                                            values={Courses}
                                                        >
                                                        </Select>
                                                        {errors.course_id?.message &&
                                                            <Typography color={'red'} fontSize={12}>
                                                                {errors.course_id?.message}
                                                            </Typography>
                                                        }
                                                    </FormControl>
                                                </Box>
                                            )}
                                        />
                                    </Box>
                                </Grid> */}
                                {/* <Grid item xs={12} sm={12} md={3} >
                                    <Box style={{ width: '100%' }}>
                                        <Controller
                                            control={control}
                                            name='alternative_course_id'
                                            render={({ field: { value, onChange } }) => (
                                                <Box display={'flex'} flexDirection={'column'} style={{ width: '100%' }}>
                                                    <FormControl >
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={value ?? ''}
                                                            label="Curso Alternativo"
                                                            onChange={onChange}
                                                            
                                                            values={Courses}
                                                        >
                                                        </Select>
                                                        {errors.alternative_course_id?.message &&
                                                            <Typography color={'red'} fontSize={12}>
                                                                {errors.alternative_course_id?.message}
                                                            </Typography>
                                                        }
                                                    </FormControl>
                                                </Box>
                                            )}
                                        />
                                    </Box>
                                </Grid> */}
                                {/* <Grid item xs={12} sm={12} md={3} >
                                    <Box style={{ width: '100%' }}>
                                        <Controller
                                            control={control}
                                            name='period_id'
                                            render={({ field: { value, onChange } }) => (
                                                <Box display={'flex'} flexDirection={'column'} style={{ width: '100%' }}>
                                                    <FormControl >
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={value ?? ''}
                                                            label="Periodo"
                                                            onChange={onChange}
                                                            
                                                            values={period}
                                                        > </Select>
                                                        {errors.period_id?.message &&
                                                            <Typography color={'red'} fontSize={12}>
                                                                {errors.period_id?.message}
                                                            </Typography>
                                                        }
                                                    </FormControl>
                                                </Box>
                                            )}
                                        />
                                    </Box>
                                </Grid> */}
                            </Grid>

                            <Box mt={2}>
                                <PnoWrap color='grayText' fontWeight={'bold'} fontSize={14} text={'Detalhes e carregamento de Documentos'} />
                            </Box>

                            <div className={styles.row}>
                                <div className={styles.blockInput}>
                                    <Controller
                                        control={control}
                                        name='document_type_id'
                                        render={({ field: { value, onChange } }) => (
                                            <Box display={'flex'} flexDirection={'column'} style={{ width: '100%' }}>
                                                <FormControl >
                                                    <Select
                                                        id="demo-simple-select"
                                                        value={value ?? ''}
                                                        label="Tipo de documento"
                                                        onChange={onChange}
                                                        
                                                        values={docsType}
                                                    ></Select>
                                                    {errors.document_type_id?.message &&
                                                        <Typography color={'red'} fontSize={12}>
                                                            {errors.document_type_id?.message}
                                                        </Typography>
                                                    }
                                                </FormControl>
                                            </Box>
                                        )}
                                    />
                                </div>
                                <div className={styles.blockInput}>
                                    <Controller
                                        control={control}
                                        name='document_number'
                                        render={({ field: { value, onChange } }) => (
                                            <FormControl  style={{ width: '100%' }}>
                                                <Input 
                                                    // error={!!errors.nif?.message}
                                                    // errorText={errors.nif?.message}
                                                    value={value ?? ''}
                                                    onChange={onChange}
                                                    label='Númer de identificação'
                                                    placeholder='Digite o seu número de indentificação'
                                                />
                                            </FormControl>
                                        )}
                                    />
                                </div>
                            </div>
                            {/* fim de linha */}

                            <div className={styles.row}>
                                <div className={styles.blockInput}>
                                    <Controller
                                        control={control}
                                        name='document_file'
                                        render={({ field: { _, onChange } }) => (
                                            <FormControl  style={{ width: '100%' }}>
                                                <Input 
                                                    type='file'
                                                    label='Ficheiro B.I / Passaporte'
                                                    // error={!!errors.document_file?.message}
                                                    // errorText={errors.document_file?.message}
                                                    onChange={(evt) => {
                                                        const file = evt.target.files[0];
                                                        if (file) {
                                                            onChange(file);  // Atualiza o estado/controlador do arquivo
                                                            handleFileChange(evt, 1);
                                                        }
                                                    }}
                                                />
                                            </FormControl>
                                        )}
                                    />
                                </div>
                                <div className={styles.blockInput}>
                                    <Controller
                                        control={control}
                                        name='certification_file'
                                        render={({ field: { _, onChange } }) => (
                                            <FormControl  style={{ width: '100%' }}>
                                                <Input 
                                                    type='file'
                                                    label='Ficheiro Certificado'
                                                    // error={!!errors.certification_file?.message}
                                                    // errorText={errors.certification_file?.message}
                                                    onChange={(evt) => {
                                                        const file = evt.target.files[0];
                                                        if (file) {
                                                            onChange(file);  // Atualiza o estado/controlador do arquivo
                                                            handleFileChange(evt, 2);
                                                        }
                                                    }}
                                                />
                                            </FormControl>
                                        )}
                                    />
                                </div>
                            </div>
                            {/* fim de linha */}

                            <Grid container spacing={2}>
                                {/* <Grid item xs={12} sm={12} md={3} >
                                    <Box style={{ width: '100%' }}>
                                        <Controller
                                            control={control}
                                            name='document_type_id'
                                            render={({ field: { value, onChange } }) => (
                                                <Box display={'flex'} flexDirection={'column'} style={{ width: '100%' }}>
                                                    <FormControl >
                                                        <Select
                                                            id="demo-simple-select"
                                                            value={value ?? ''}
                                                            label="Tipo de documento"
                                                            onChange={onChange}
                                                            
                                                            values={docsType}
                                                        ></Select>
                                                        {errors.document_type_id?.message &&
                                                            <Typography color={'red'} fontSize={12}>
                                                                {errors.document_type_id?.message}
                                                            </Typography>
                                                        }
                                                    </FormControl>
                                                </Box>
                                            )}
                                        />
                                    </Box>
                                </Grid> */}
                                {/* <Grid item xs={12} sm={12} md={3} >
                                    <Box style={{ width: '100%' }}>
                                        <Controller
                                            control={control}
                                            name='document_number'
                                            render={({ field: { value, onChange } }) => (
                                                <FormControl  style={{ width: '100%' }}>
                                                    <Input 
                                                        error={!!errors.nif?.message}
                                                        errorText={errors.nif?.message}
                                                        value={value ?? ''}
                                                        onChange={onChange}
                                                        label='Númer de identificação'
                                                        placeholder='Digite o seu número de indentificação'
                                                    />
                                                </FormControl>
                                            )}
                                        />
                                    </Box>
                                </Grid> */}

                                {/* <Grid item xs={12} sm={12} md={3} >
                                    <Box style={{ width: '100%' }}>
                                        <Controller
                                            control={control}
                                            name='document_file'
                                            render={({ field: { _, onChange } }) => (
                                                <FormControl  style={{ width: '100%' }}>
                                                    <Input 
                                                        type='file'
                                                        label='Ficheiro B.I / Passaporte'
                                                        error={!!errors.document_file?.message}
                                                        errorText={errors.document_file?.message}
                                                        onChange={(evt) => {
                                                            const file = evt.target.files[0];
                                                            if (file) {
                                                                onChange(file);  // Atualiza o estado/controlador do arquivo
                                                                handleFileChange(evt, 1);
                                                            }
                                                        }}
                                                    />
                                                </FormControl>
                                            )}
                                        />
                                    </Box>
                                </Grid> */}

                                {/* <Grid item xs={12} sm={12} md={3} >
                                    <Box style={{ width: '100%' }}>
                                        <Controller
                                            control={control}
                                            name='certification_file'
                                            render={({ field: { _, onChange } }) => (
                                                <FormControl  style={{ width: '100%' }}>
                                                    <Input 
                                                        type='file'
                                                        label='Ficheiro Certificado'
                                                        error={!!errors.certification_file?.message}
                                                        errorText={errors.certification_file?.message}
                                                        onChange={(evt) => {
                                                            const file = evt.target.files[0];
                                                            if (file) {
                                                                onChange(file);  // Atualiza o estado/controlador do arquivo
                                                                handleFileChange(evt, 2);
                                                            }
                                                        }}
                                                    />
                                                </FormControl>
                                            )}
                                        />
                                    </Box>
                                </Grid> */}

                                <Grid item xs={12} sm={12} md={6} height={140} overflow={'auto'}>
                                    <Box bgcolor={'lightyellow'} borderRadius={2} width={'100%'} height={'100%'}>
                                        {fileURL && <PdfPreview pdfFile={fileURL} />}
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} height={140} overflow={'auto'}>
                                    <Box bgcolor={'lightyellow'} borderRadius={2} width={'100%'} height={'100%'}>
                                        {fileURL1 && <PdfPreview pdfFile={fileURL1} />}
                                    </Box>
                                </Grid>

                                <section className={`${styles.blockBtn}`}>
                                    <div className={`${styles.row}  ${styles.rowBackground}`}>
                                        <div className={styles.blockInput}>
                                            <button
                                                className={`${styles.btnForm}  ${styles.btnSave}`}
                                                type='submit'
                                                onSubmit={handleSubmit(handleSubmitInscription)}
                                            >
                                                Guardar
                                            </button>
                                        </div>
                                        <div className={styles.blockInput}>
                                            <button
                                                className={`${styles.btnForm}  ${styles.btnBack}`}
                                                onClick={() => navigate('/inscription')}
                                            >
                                                voltar
                                            </button>
                                        </div>
                                    </div>
                                    {/* fim de link */}
                                </section>

                                {/* <Grid item xs={12} sm={12} md={12} mb={2} >
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        
                                        type='submit'
                                        disabled={Loading}
                                        onSubmit={handleSubmit(handleSubmitInscription)}
                                        endIcon={
                                            Loading ?
                                                <CircularProgress size={'1rem'} /> :
                                                <Icon>save</Icon>
                                        }
                                        sx={{ fontSize: 16, mb: 2 }}
                                    >
                                        Guardar
                                    </Button>

                                    <Button
                                        variant='contained'
                                        color='secondary'
                                        
                                        disabled={Loading}
                                        onClick={() => navigate('/inscription')}
                                        sx={{ fontSize: 16 }}
                                    >
                                        voltar
                                    </Button>
                                </Grid> */}
                            </Grid>
                        </Box>
                    </div>
                </div>
            </section>
        </section>
    )
}
