// styles
import styles from './EscolaRegister.module.css';
// dependencies
import React from 'react';
// components
import { Head } from '../../shared';
import Header from '../../components/Header';
import Input from '../../components/formComponents/Input';
import Button from '../../components/formComponents/Button';
import Select from '../../components/formComponents/Select';

const EscolaRegister = () => {
    const [form, setForm] = React.useState(true);

    const escolaOptions = [
        { id: 1, name: 'Escola Publica' },
        { id: 2, name: 'Escola Privada' },
        { id: 3, name: 'Escola Comparticipada' },
    ];

    const ensinoOptions = [
        { id: 1, name: 'Puniv' },
        { id: 2, name: 'Técnica' },
        { id: 3, name: 'Politécnico' },
    ];

    const generoOptions = [
        { id: 1, name: 'Masculino' },
        { id: 2, name: 'Femenino' },
    ];

    return (
        <React.Fragment>
            <Head title='Registar Escola' />
            <Header />
            <main className={styles.register}>
                <h1 className='title'>Cadastrar Escola</h1>
                <form>
                    {form ? (
                        <section>
                            <p className={styles.subtitle}>Preencha todos os dados da escola</p>

                            <div className={styles.blockInput}>
                                <Input label='Nome da Escola' id='name' name='name' placeholder='Digite o nome da escola' />
                                <Input label='Logotipo' type='file' id='logo' name='logo' placeholder='Digite o nome da província' />
                            </div>
                            <div className={styles.blockInput}>
                                <Select label='Tipo de Escola' id='type' name='type' placeholder='Selecione o tipo de escola'
                                    values={escolaOptions}
                                />
                                <Input label='Província' id='provincia' name='provincia' placeholder='Digite o nome da província' />
                            </div>
                            <div className={styles.blockInput}>
                                <Input label='Município' id='municipio' name='municipio' placeholder='Digite o nome do município' />
                                <Select label='Nível de Ensino' id='nivel' name='nivel' placeholder='Selecione o nível de ensino'
                                    values={ensinoOptions}
                                />
                            </div>
                            <div className={styles.blockInput}>
                                <Input label='Decreto de Criação' id='decreto' name='decreto' placeholder='Digite o decreto de criação' />
                                <Input label='Número da Escola' id='numero' name='numero' placeholder='Digite o número da escola' />
                            </div>
                            <div className={styles.blockInput}>
                                <Input label='Ponto de Referência' id='referencia' name='referencia' placeholder='Digite o ponto de referência' />
                                <Input label='Email da Escola' type='email' id='email' name='email' placeholder='Digite o email da escola' />
                            </div>
                            <Button onClick={() => setForm(false)}>Seguinte</Button>
                        </section>
                    ) :
                        (
                            <section>
                                <p className={styles.subtitle}>Preencha todos os dados do director</p>
                                <Input label='Nome do Director' id='director' name='director' placeholder='Digite o nome do director' />
                                <div className={styles.blockInput}>
                                    <Input label='Imagem' type='file' id='img_director' name='img_director' placeholder='Digite o nome do director' />
                                    <Input label='Número do BI' id='bi' name='bi' placeholder='Digite o número do bilhete de identidade' />
                                </div>
                                <div className={styles.blockInput}>
                                    <Input label='Data de Nascimento' type='date' id='nascimento' name='nascimento' placeholder='Digite o nome do director' />
                                    <Select label='Gênero' id='genero' name='genero' placeholder='Selecione o gênero'
                                        values={generoOptions}
                                    />
                                </div>
                                <div className={styles.blockBtn}>
                                    <Button onClick={() => setForm(true)}>Voltar</Button>
                                    <Button type='submit'>Registar</Button>
                                </div>
                            </section>
                        )
                    }
                </form>
            </main>
        </React.Fragment>
    )
}

export default EscolaRegister
