import React, { useMemo, useState } from 'react'
import { MenuDrawer, PnoWrap, StudentsRegister } from '../../components'
import { Environment, Head, inscription_services } from '../../shared'
import {
    Box, Divider, Grid, Icon, IconButton, InputBase, LinearProgress, Pagination,
    Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow,
    TextField,
    Typography, useTheme
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { useSearchParams, Link } from 'react-router-dom';
import { useAuthContext } from '../../shared/hooks';
import { toast } from 'react-toastify';
import { PrivateSession } from '../../shared/hooks/usePermission';

export function StudentSelected() {
    const { palette } = useTheme();
    const [data, setData] = useState([]);
    const [userRegister, setUserRegister] = useState(null);
    const [openModalRegister, setOpenModalRegister] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [SearchParmams, setSearchParmams] = useSearchParams({ page: 1 });

    const { token } = useAuthContext()
    const [totalAccount, setTotalAccount] = React.useState(0);
    const [Change, setChange] = React.useState(false);

    const search = useMemo(() => {
      return SearchParmams?.get('search') || ''
    }, [SearchParmams])
  
    const page = useMemo(() => {
      return SearchParmams?.get('page') || 1
    }, [SearchParmams])

    // const deletestudent = async (id) => {
    //     try {
    //         await studentServices.delOne(id);
    //         alert('Alunos eliminado com sucesso')
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    React.useEffect(() => {
        const findAllCourses = async () => {
          try {
            setLoading(true);
            const response = await inscription_services.getAllCandidateSelected(page, Environment.ROW_LIMIT, search ?? '', token ?? '');
            console.log({response});
            
            setData(response?.data??[]);
            setTotalAccount(response?.meta.total)
          } catch (error) {
            if (error.message === 'Failed to fetch')
              toast.error('Problemas de conexão com a internet!')
            toast.error(error.message)
    
          } finally {
            setLoading(false);
          }
        }
        findAllCourses();
        setChange(false)
    }, [search, page, Change]);

      
    //   const setNoteStudent = async (average, user_id) => {
    //     try {
    //         const result = await inscription_services.setNote({average, user_id}, token)
            
    //         console.log(result);
            
    //         if(result.data) {
    //             toast.success('Nota atribuida com sucesso')

    //             setChange(true)
    //             return;
    //         }
    //         return toast.error('Problemas ao atribuir a nota!');
    //     } catch (error) {
    //         console.error({error})
    //         return toast.error('Problemas ao atribuir a nota!');  
    //     }
    //   }

      const register = (id) => {
        setOpenModalRegister(true);
        setUserRegister(data[data.findIndex(u => u.id === id)]);
        
    }
    console.log({userRegister});

    return (
        <MenuDrawer page='Candidatos Selecionado'>
            <Head title='Candidatos Selecionado' />
            {openModalRegister && <StudentsRegister setCloseModal={setOpenModalRegister} user={userRegister}/>}
            <Box component={Paper} py={2} m={2} flex={1}>
                <Box m={1} ml={2}>
                    <Box m={1} >
                        <Grid container sx={{ display: 'flex', alignItems: 'center', py: 2 }} spacing={1} gap={1} >
                            <section className='bloco-pesquisa-botao'> 
                                <PrivateSession menu={["candidate_selected_TM"]} permissionRoute={1}>
                                    <Grid item>
                                        <Paper
                                            component="form"
                                            sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 250, border: .1, borderColor: 'gray' }}
                                        >
                                            <InputBase
                                                size='small'
                                                sx={{ ml: 1, flex: 1 }}
                                                placeholder="Pesquisar"
                                                onChange={(evt) => setSearchParmams({search: evt.target.value}, { replace: false})}
                                                inputProps={{ 'aria-label': 'search google maps' }}
                                            />
                                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                                <SearchIcon />
                                            </IconButton>
                                        </Paper>
                                    </Grid>
                                </PrivateSession>
                            {/* <Grid item>
                                    <button onClick={() => {}} className='btn btn-select'>
                                        Gerar as médias <i className='fas fa-circle-check'></i>
                                    </button>
                            </Grid> */}
                                </section>
                        </Grid>
                        <Divider />
                    </Box>
                    <PrivateSession menu={["candidate_selected_TM"]} permissionRoute={1}>
                        <Box marginBottom={5}>
                            <Box p={1} display={'flex'} width={'auto'} justifyContent={'flex-end'}>
                                <TableContainer component={Paper} elevation={3} sx={{ flexGrow: 1 }} >
                                    <Table sx={{ minWidth: 650, color: 'white' }} size='small' aria-label="a dense table">
                                        <TableHead sx={{ backgroundColor: `${palette.primary.main}`, '&:last-child td': { color: 'white' } }}>
                                            <TableRow>
                                                <TableCell className='title-table text-center'><PnoWrap color='white' text='Nº de Processo' /></TableCell>
                                                <TableCell className='title-table text-center'>Nome</TableCell>
                                                <TableCell className='title-table text-center'>Curso</TableCell>
                                                <TableCell className='title-table text-center'>Turma</TableCell>
                                                <TableCell className='title-table text-center'><PnoWrap color='white' text='Nota do exame' /></TableCell>
                                                <TableCell className='title-table text-center'>Acções</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.length > 0 &&
                                                data.map((item, index) => (
                                                    <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell className='text-center'>{item?.document?.value}</TableCell>
                                                        <TableCell className='text-center'>{item.name}</TableCell>
                                                        <TableCell className='text-center'>{item.application.courses.name}</TableCell>
                                                        <TableCell className='text-center'>{item.turma}</TableCell>
                                                        <TableCell className='text-center'>
                                                            <Box position={'relative'} display={'flex'}  sx={{width: 70}}>
                                                                <TextField
                                                                    size='small'
                                                                    disabled={!!item.media_exam_access?.average}
                                                                    value={item.media_exam_access?.average ??''}
                        
                                                                />
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Box display={'flex'} gap={1} justifyContent={'center'}>
                                                                <PrivateSession menu={["candidate_selected_TM"]} permissionRoute={4}>
                                                                    <Link className='btn-icon' to={`/people/students/show/${item.id}`}>
                                                                        <i className="fa-solid fa-eye"></i>
                                                                    </Link>
                                                                </PrivateSession>
                                                                {/* <Link to={`/people/students/update/${item.id}`}>
                                                                    <Typography className='btn-icon btn-edit'>
                                                                        <i className="fa-solid fa-edit"></i>
                                                                    </Typography>
                                                                </Link>
                                                                <Typography className='btn-icon btn-edit' onClick={() => deletestudent(item.id)}>
                                                                    <i className="fa-solid fa-trash"></i>
                                                                </Typography> */}
                                                                <PrivateSession menu={["candidate_selected_TM"]} permissionRoute={3}>
                                                                    <button className='btn-icon' title={`Matricular o(a) candidato(a) ${item.nome}`} onClick={() => register(item.id)}>
                                                                        <i className="fas fa-check"></i>
                                                                    </button>
                                                                </PrivateSession>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                            {loading && (
                                                <TableRow>
                                                    <TableCell colSpan={10} align="center"><LinearProgress color='secondary' /></TableCell>
                                                </TableRow>)
                                            }
                                        </TableBody>
                                        <TableFooter>
                                            {data.length === 0 && (
                                                <TableRow>
                                                    <TableCell colSpan={12} align="center"><Typography width={250}>Lista de produtos vazia</Typography></TableCell>
                                                </TableRow>
                                            )}
                                            <TableRow>
                                                <TableCell colSpan={10} align="center" sx={{ pb: 2 }}>
                                                    <Pagination
                                                        count={Math.ceil(totalAccount / Environment.ROW_LIMIT)}
                                                        page={Number(page)} onChange={(_, newPage) => { setSearchParmams({ _, page: newPage.toString() }, { replace: true }) }}
                                                        color='primary'
                                                        size='small'
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                    </PrivateSession>
                </Box>

            </Box>

        </MenuDrawer>
    )
}
