import styles from './Header.module.css';
import React from 'react';
import { Link } from 'react-router-dom';
// import Logo from '../assets/images/image-logo-copy.png';
import { ReactComponent as Logo } from '../assets/svg/infoq-logo.svg';

const Header = () => {
    const [active, setActive] = React.useState(false);

    return (
        <header className={styles.header}>
            {/* desktop */}
            <nav className={styles.desktop}>
                <Link to='/'>
                    {/* <img src={Logo} alt='logo do site' /> */}
                    <Logo />
                </Link>

                <ul>
                    <li><Link to='/'>Home</Link></li>
                    <li><Link to='/about'>Sobre</Link></li>
                    <li><Link to='/school'>Institutos</Link></li>
                    <li><Link to='/school'>Cursos disponíveis</Link></li>
                    <li><Link to='/school'>Orientação</Link></li>
                    <li><Link to='/school'>Calendário das Etapas</Link></li>
                </ul>

                <div className={styles.blockBtn}>
                    <Link to='/escola/cadastrar' className={styles.cadastro}>Cadastrar Escola</Link>
                    <Link to='/login' className={styles.login}>Entrar</Link>
                </div>
            </nav>
            {/* mobile */}
            <nav className={styles.mobile}>
                <Link to='/'>
                    <img src={Logo} alt='logo do site' />
                </Link>

                <button className={styles.btnMobile} onClick={() => setActive(!active)}>
                    <i className="fa-solid fa-bars"></i>
                </button>
                <div className={active ? styles.blocoItensActive : styles.blocoItens}>
                    <ul>
                        <li><Link to='/'>Home</Link></li>
                        <li><Link to='/about'>Sobre</Link></li>
                        <li><Link to='/school'>Institutos</Link></li>
                        <li><Link to='/school'>Cursos disponíveis</Link></li>
                        <li><Link to='/school'>Orientação</Link></li>
                        <li><Link to='/school'>Calendário das Etapas</Link></li>
                        <li><Link to='/school'>Diário da Republica</Link></li>
                        <li><Link to='/school'>Exame de acesso ao INFQE</Link></li>
                        <li><Link to='/school'>Decreto Executivo do Mês</Link></li>
                    </ul>

                    <Link to='/login'>Entrar</Link>
                </div>
            </nav>
        </header>
    )
}

export default Header
