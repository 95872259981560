import React, { createContext, useEffect, useState } from "react";
import { useLocalData } from "../hooks";
import { sessionServices } from "../services/auth";
import { deleteTokenLocalData, deleteUserLocalData } from "../LocalData";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const SessionContext = createContext({
    user: undefined,
    token: null,
    isLoading: false,
    setIsLoading: (value) => { },
    logout: () => { },
    session: async (email, password) => { },
});

export const SessionContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [Menu, setMenus] = useState(null);
    const [userRoles, setUserRules] = useState(null);
    const [isLoading, setIsLoading] = useState(true)
    const [token, setToken] = useState(null)
    const { getUserLocalData, getTokenLocalData, setUserPermissionsLocalData, getUserPermissionsLocalData, setTokenLocalData, setUserLocalData,cleanLocalData } = useLocalData()
    const navigate = useNavigate()
    // const [control, setControl] = useState({id: 1, exist: false});
    const [existUser, setExistUser] = React.useState(false)

    const handleGetOneRules = async (id) => {
        const result = await getOneRules(id)
    
        if(result instanceof Error) {
          console.log(result);
        }
        setUserRules(result?.data?.menus ?? [])
    }


    useEffect(() => {
        const userLocal = getUserLocalData();
        const tokenLocal = getTokenLocalData();
        const menu = getUserPermissionsLocalData()
        if (userLocal) {
            setUser(JSON.parse(userLocal));
            setToken(JSON.parse(tokenLocal));
            setMenus(JSON.parse(menu))
        }
        setIsLoading(false);
    }, [existUser]);

    useEffect(() => {
        if(user)
            handleGetOneRules(user?.roles.id);
    }, [user])
    
    const session = async (email, password) => {
        try {
            setIsLoading(true);
            const result = await sessionServices.login({ email, password })
            
            if (result.data && result.data.user) {
                const { user, access_token } = result.data;
                setTokenLocalData(access_token)
                setToken(access_token)
                setUserLocalData(user);
                const menu = await getMenus(access_token);

                if(menu instanceof Error) {
                    console.log({menuError: menu}); 
                }
                setUserPermissionsLocalData(menu?.data)
                setMenus(menu?.data ?? [])                
                setIsLoading(false)
                setExistUser(!existUser)
                return true;
            }

            if(result.error)
                
            toast.error(result.error === 'Unauthorized' ? 'Não autorizado, verifique se inseriu informações corretas!': result.error)
        } catch (error) {
            toast.error(error.message);
        } finally {
            setIsLoading(false)
        }
    }

    const addUser = async (data) => {
        try {
            const result = await sessionServices.loginRegister(data)
            
            if (result.data) {
                return true;
            }

            return result;
        } catch (error) {
            throw new Error(error.message)
        } 
    }


    const addRules = async ({name, permissions }) => {
        try {
            const result = await sessionServices.CreateRules({ name, permissions })
            
            console.log({result});
            if (result.data) {
                return true;
            }

        } catch (error) {
            throw new Error(error.message)
        } 
    }

    const getRules = async () => {
        try {
            const result = await sessionServices.getRules(token)
            
            if (result.data) {
                return result;
            }
        return ;
        } catch (error) {
            throw new Error(error.message)
        } 
    }



    const getOneRules = async (id) => {
        try {
            const result = await sessionServices.getOneRules(id, token)
            
            if (result.data) {
                return result;
            }
        return ;
        } catch (error) {
            throw new Error(error.message)
        } 
    }

    const getMenus = async (access_token) => {
        try {
            const result = await sessionServices.getMenus(access_token)
            
            if (result.data) {
                return result;
            }

        return ;
        } catch (error) {
            throw new Error(error.message)
        } 
    }

    const getPermissions = async () => {
        try {
            const result = await sessionServices.getPermissions()
            
            if (result.data) {
                return result;
            }
            return;
        } catch (error) {
            throw new Error(error.message)
        } 
    }

    const getPermissionsRules = async () => {
        try {
            const result = await sessionServices.getPermissionsRules()
            
            if (result.data) {
                return result;
            }
            return;
        } catch (error) {
            throw new Error(error.message)
        } 
    }


    const updatePermissionsRules = async (id_rules, data) => {
        try {
            const result = await sessionServices.updateRules(id_rules, data)
            
            if (result.data) {
                return result;
            }
            
        } catch (error) {
            throw new Error(error.message)
        } 
    }

    const logout = () => {
        setUser(null);
        setToken(null)
        deleteUserLocalData()
        deleteTokenLocalData();
        cleanLocalData()        
        navigate('/');
    }

    return (
        <SessionContext.Provider value={{
            getRules, 
            updatePermissionsRules,
            getPermissionsRules, 
            getPermissions, 
            addRules, 
            user, token, logout, 
            isLoading, setIsLoading, 
            session ,
            addUser,
            Menu,
            getMenus,
            getOneRules,
            userRoles
        }}>
            {children}
        </SessionContext.Provider>
    )
}
