
const USER_DTO = '@USER_DTO';
const TOKEN_DTO = '@TOKEN_DTO';
const CODE_DTO = '@CODE_DTO';

export const getUserPermissionsLocalData = () => {
    const data = localStorage.getItem(CODE_DTO);
    return data;
}

export const setUserPermissionsLocalData = (data) => {
    localStorage.setItem(CODE_DTO, JSON.stringify(data));
    return;
}

export const deleteUserPermissionsLocalData = (data) => {
    localStorage.removeItem(CODE_DTO, JSON.stringify(data));
    return;
}
export const getUserLocalData = () => {
    const data = localStorage.getItem(USER_DTO);
    return data;
}

export const setUserLocalData = (data) => {
    localStorage.setItem(USER_DTO, JSON.stringify(data));
    return;
}

export const deleteUserLocalData = (data) => {
    localStorage.removeItem(USER_DTO, JSON.stringify(data));
    return;
}

export const getTokenLocalData = () => {
    const data = localStorage.getItem(TOKEN_DTO);
    return data;
}

export const setTokenLocalData = (data) => {
    localStorage.setItem(TOKEN_DTO, JSON.stringify(data));
    return;
}

export const deleteTokenLocalData = (data) => {
    localStorage.removeItem(TOKEN_DTO, JSON.stringify(data));
    return;
}


export const cleanLocalData = (data) => {
    localStorage.clear();
    return;
}

