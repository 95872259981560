import { Box, Button, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'

import { PnoWrap } from '../paragraphy';
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'
import Select from '../../components/formComponents/Select'
import { Link, useInRouterContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import { classroomService, GET_CLASSROOMS } from '../../shared';
import { useAuthContext } from '../../shared/hooks';
import Input from '../formComponents/Input';


const schema = yup.object().shape({
    user_id: yup.number().required('Candidato é obrigaório').moreThan(0),
    classroom_id: yup.number().required('Turma é obridatório').moreThan(0),
    father_affiliation: yup.string().notRequired(),
    mother_affiliation: yup.string().notRequired(),
    application_id: yup.number().required().moreThan(0),
    current_address: yup.string().required('morada é obrigatório'),
    contact: yup.string().required('contacto é obridatório'),
    military_document: yup.mixed(),
})


export function StudentsRegister({setCloseModal, user}) {
  const { zIndex } = useTheme()
  const { control, handleSubmit, formState: { errors }, setValue } = useForm({
      resolver: yupResolver(schema), defaultValues: {
        user_id: undefined,
        classroom_id: undefined,
        father_affiliation: '',
        mother_affiliation: '',
        application_id: undefined,
        current_address: undefined,
        contact: '',
        military_document: undefined,
      }
  });
  const [loading, setLoading] = useState(false);
  const [pdf, setPdf] = useState(null);
  const [classroom, setClassRoom] = useState(false);
  const { token } = useAuthContext();

  React.useEffect(() => {
    const findAllClasses = async () => {
        try {
            const { url, options } = GET_CLASSROOMS(1, 100000000000000000, '', token);
            const response = await fetch(url, options);
            const result = await response.json();
            
            setClassRoom(result.data);
        } catch (error) {
          console.log('====================================');
          console.log({error});
          console.log('====================================');
        } finally {
            setLoading(false);
        }
    }
    findAllClasses();
}, []);

const cleanData = () => {
  setValue('application_id', undefined);
  setValue('user_id', undefined);
  setValue('classroom_id', undefined);
  setValue('current_address', undefined);
  setValue('military_document', undefined);
  setValue('contact', '');
  setValue('father_affiliation', '');
  setValue('mother_affiliation', '');

}

  const submit = async function (data) {
    setLoading(true)
    try {
      if(!data.father_affiliation && !data.mother_affiliation) {
        return toast.error('Precisa preencher os dados de seus parentes.');
      }

      if(!data.father_affiliation && data.mother_affiliation) {
        data.father_affiliation = 'XXXXXXXXXXXXXXX'
      }

      if(data.father_affiliation && !data.mother_affiliation) {
        data.mother_affiliation = 'XXXXXXXXXXXXXXX'
      }

      const response = await classroomService.POST_REGISTRATION(data, token);

      if(response && response.errors){
        return toast.error(response.message);
      }

      if(response && response.data && response.data.pdf_probative_registration){
        setPdf(response.data.pdf_probative_registration)
        return toast.success('Matricula feita com sucesso.');
      }

    } catch (error) {
      
    }finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setValue('application_id', user.application?.id);
    setValue('user_id', user?.id);
  }, [user]);


  return (
    <Box top={0} left={0}
     position={'absolute'} 
     zIndex={zIndex.drawer + 15} 
     width={'100vw'} height={'100vh'}
     sx={{background: 'rgba(55,55, 20, .1)'} }
     display={'flex'}
     alignItems={'center'}
     justifyContent={'center'}
    >
      <Box height={550} display={'flex'} flexDirection={'column'} width={900} bgcolor={'white'} padding={2} borderRadius={6} boxShadow={2} overflow={'hidden'}>
        <Box display={'flex'} justifyContent={'space-between'} mb={1}>
          <PnoWrap text={'Matricular'} fontSize={18} fontWeight={'bold'} color='#77B43F'/>
          <Box>
            <i className="fa fa-remove" onClick={() => setCloseModal(false)}></i>
          </Box>
        </Box>
        <Box mb={2} overflow={'auto'} flexGrow={1} component={'form'} noValidate onSubmit={handleSubmit(submit)}>
            {/* <PnoWrap text={title} fontSize={18} fontWeight={'bold'} color='#77B43F'></PnoWrap> */}
            <Box display={'flex'} gap={2}>
              
                    <Box my={2} width={'100%'}>
                      <PnoWrap text={'Nome Completo'} color='grayText'/>
                      <Input disabled  value={user.name??''} />
                    </Box>

                
                    <Box my={2} width={'100%'}>
                      <PnoWrap text={'Número de identidade'} color='grayText'/>
                      <Input disabled value={user.document?.value??''} />
                    </Box>
            </Box>
            <Box display={'flex'} gap={2}>
              <Controller
                  control={control}
                  name='father_affiliation'
                  render={({field: {onChange, value}}) => (
                    <Box my={2} width={'100%'}>
                      <PnoWrap text={'Nome Completo do Pai'} color='grayText'/>
                      <Input placeholder={'Informe o nome do pai'} errorText={errors.name?.message} error={!!errors.name?.message} value={value??''} onChange={(e) => onChange(e.target.value)}/>
                    </Box>
                  )}
                  />
                <Controller
                  control={control}
                  name='mother_affiliation'
                  render={({field: {onChange, value}}) => (
                    <Box my={2} width={'100%'}>
                      <PnoWrap text={'Nome Completo da Mãe'} color='grayText'/>
                      <Input placeholder={'Informe o nome da mãe'} errorText={errors.min_students?.message} error={!!errors.min_students?.message} value={value??''} onChange={(e) => onChange(e.target.value)}/>
                    </Box>
                    )}
                  />
            </Box>
            <Box display={'flex'} gap={2}>
              <Box width={'100%'}>
                    {/* <PnoWrap text={'Área de formação'} color='grayText'/> */}
                    <Controller
                      control={control}
                      name='classroom_id'
                      render={({ field: { value, onChange } }) => (
                          <Box display={'flex'} flexDirection={'column'} style={{ width: '100%' }}>
                              <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={value ?? ''}
                                  label="Selecione a turma"
                                  values={classroom}
                                  onChange={(e) => onChange(e.target.value) }
                              />
                              {errors.classroom_id?.message &&
                                  <Typography color={'red'} fontSize={12}>
                                      {errors.classroom_id?.message}
                                  </Typography>
                              }
                          </Box>
                      )}
                  />
                  </Box>

                  <Box width={'100%'}>
                    {/* <PnoWrap text={'Área de formação'} color='grayText'/> */}
                    <Controller
                      control={control}
                      name='application_id'
                      render={({ field: { value } }) => (
                        <Box width={'100%'}>
                        <PnoWrap text={'Código de inscrito'} color='grayText'/>
                        <Input disabled value={value??''} />
                      </Box>
                      )}
                  />
                  </Box>
            </Box>
            
            <Box display={'flex'} gap={2}>
              <Controller
                  control={control}
                  name='current_address'
                  render={({field: {onChange, value}}) => (
                    <Box my={2} width={'100%'}>
                      <PnoWrap text={'Morada actual'} color='grayText'/>
                      <Input placeholder={'Informe a morada do estudante'}  errorText={errors.name?.message} error={!!errors.name?.message} value={value??''} onChange={(e) => onChange(e.target.value)}/>
                    </Box>
                  )}
                  />
                <Controller
                  control={control}
                  name='military_document'
                  render={({field: {onChange, value}}) => (
                    <Box my={2} width={'100%'}>
                      <PnoWrap text={'Documento Militar'} color='grayText'/>
                      <Input type={'file'} title={'carrege o documento'} errorText={errors.min_students?.message} error={!!errors.min_students?.message} value={value??''} onChange={(e) => onChange(e.target.value)}/>
                    </Box>
                    )}
                  />
            </Box>
            <Box display={'flex'} gap={2}>
              <Controller
                  control={control}
                  name='contact'
                  render={({field: {onChange, value}}) => (
                    <Box my={2} width={'100%'}>
                      <PnoWrap text={'Contacto'} color='grayText'/>
                      <Input placeholder={'Informe o seu contacto'} errorText={errors.name?.message} error={!!errors.name?.message} value={value??''} onChange={(e) => onChange(e.target.value)}/>
                    </Box>
                  )}
                  />
            </Box>
            <Box display={'flex'} alignItems={'center'}>
              <Button variant='contained' type='submit' disabled={loading}>
                {!loading ? 'Concluir a matricula': '...' }
              </Button>
              <Box mx={4}>
                {pdf && <Link to={pdf} target='_blank' onClick={() => {setCloseModal(false); cleanData();}} >
                  Baixar Comprovante
                </Link>}
              </Box>
            </Box>
        </Box>
      </Box>
    </Box>
  )
}
