import { api_services } from './config';


const login = async (data) => {    
    try {
        const result = await api_services('/auth/login', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(data)
        })
        return result.json()
    } catch (error) {
        console.log('Erro ao logar', error);  
        return new error(error);      
    }
}

const me = async (token) => {    
    try {
        const result = await api_services('/auth/me', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        return result.json()
    } catch (error) {
        console.log('Erro ao me', error);  
        return error;      
    }
}

const CreateRules = async (id_rules, data, token) => {    
    try {
        const result = await api_services('/auth/roles', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data)
            
        })
        return result.json()
    } catch (error) {
        console.log('Erro ao Cadastrar Regras', error);  
        return error;      
    }
}

const updateRules = async (id_rules, data, token) => {   
    console.log({id_rules, data});
    
    try {
        const result = await api_services(`/auth/roles/${id_rules}`, {
            method: 'PUT',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data)
            
        })

        console.log({result});
        
        return result.json()
    } catch (error) {
        console.log('Erro ao Cadastrar Regras', error);  
        return error;      
    }
}

const getRules = async (token) => {    
    try {
        const result = await api_services('/auth/roles', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            }
            
        })
        return result.json()
    } catch (error) {
        console.log('Erro ao buscar as Regras', error);  
        return error;      
    }
}



const getOneRules = async (id, token) => {    
    try {
        const result = await api_services(`/auth/roles/${id}`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            }
            
        })
        return result.json()
    } catch (error) {
        console.log('Erro ao buscar as Regras', error);  
        return error;      
    }
}



const getMenus = async (token) => {    
    try {
        const result = await api_services('/sys/menus', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            }
            
        })
        return result.json()
    } catch (error) {
        console.log('Erro ao buscar as Regras', error);  
        return error;      
    }
}


const getPermissions = async (token) => {    
    try {
        const result = await api_services('/auth/permissions', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            }
            
        })
        return result.json()
    } catch (error) {
        console.log('Erro ao buscar as Regras', error);  
        return error;      
    }
}


const getPermissionsRules = async (token) => {    
    try {
        const result = await api_services('/auth/permissions/roles', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            }
            
        })
        return result.json()
    } catch (error) {
        console.log('Erro ao buscar as Regras', error);  
        return error;      
    }
}
const loginRegister = async (data, token) => {
    console.log({...data});
    try {
        const result = await api_services('/auth/register', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({...data})
        })
         return result.json();
    } catch (error) {
        console.log('Erro ao registrar sessão', error);        
    }
}

export const sessionServices = {
    login,
    loginRegister,
    me,
    CreateRules,
    updateRules,
    getRules,
    getPermissions,
    getPermissionsRules,
    getMenus,
    getOneRules
    
}