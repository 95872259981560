import { useContext, useEffect, useState } from "react"
import { SessionContext } from "../contexts";

import { useAuthContext, useLocalData } from "../hooks";


// export const usePermission = () => {
//     return useContext(PermissionContext);
// }

export const PrivateSession = ({ children, menu = [], isMenuList = false,titleSelected, permissionRoute, isMenu = false}) => {
    const { userRoles } = useContext(SessionContext);
    const { Menu } = useAuthContext();
    const [existOPT, setExistOPT] = useState(false);
    const a = ["dashboard_TM",
                "student_TM",
                "candidate_inscri_TM",
                "candidate_selected_TM",
                "functionary_TM",
                "training_area_TM",
                "school_TM",
                "course_TM",
                "classes_TM",
                "discipline_TM",
                "curricular_grade_TM",
                "internal_location_TM",
                "especial_exam_TM",
                "recurse_TM",
                "update_nota_TM",
                "list_nota_TM",
                "frequency_declaration_TM",
                "frequency_nota_TM",
                "transference_TM",
                "certificate_TM",
                "register_TM",
                "confirm_register_TM",
                "term_frequency_TM",
                "uniform_TM",
                "publish_vag_TM",
                "presence_TM",
                "fault_TM",
                "justify_fault_TM",
                "energy_water_TM",
                "school_support_TM",
                "system_support_TM",
]



    // // const handleGetMenu = async () => {
    // //     const result = await getMenu()
    
    // //     if(result instanceof Error) {
    // //       console.log(result); 
    // //     }
    // //     setMenu(result?.data ?? [])
    // // }

    // useEffect(() => {

    //     const doc = document.documentElement.getElementsByClassName('menu-main');
    //     [...doc].forEach(el => {
    //         let a = el.getElementsByClassName('main-text')
        
    //         if(titleSelected === a[0].innerText) {
    //             console.log('\n\nAbaixo ==== \n',{titleSelected, item: a[0].innerText}, titleSelected === a[0].innerText);
                
    //             setExistOPT(true);
    //         }
    //     }); 
        
    // }, []);

        
    
    
    if(!isMenu && Menu && userRoles && userRoles[userRoles?.findIndex((me) => me.menu_id === Menu[Menu?.findIndex((m) => menu.includes(m.name))]?.id)]?.permissions?.includes(permissionRoute)) {
        return children;
    }
    // if(isMenuList && existOPT) return; 
    
    if(isMenuList && Menu && userRoles && userRoles[userRoles?.findIndex((me) => me.menu_id === Menu[Menu?.findIndex((m) => menu.includes(m.name))]?.id)]?.permissions?.length > 0) {
    
        return children;
    }
    
    if(isMenu && Menu && userRoles && userRoles[userRoles?.findIndex((me) => me.menu_id === Menu[Menu?.findIndex((m) => menu.includes(m.name))]?.id)]?.permissions?.length > 0) {
        return children;
    }
    return null;
}